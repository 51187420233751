<script>
import Layout from "@/router/layouts/main";
import PageHeader from '@/components/page-header'
import appConfig from "@/app.config";
import StoreService from '@/services/store'
import defaultStoreImage from '../../../assets/images/default-store.png'

export default {
  page: {
    title: "Configurações",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Configurações",
      defaultStoreImage,
      store: {},
    };
  },
  mounted() {
    this.load()
  },
  methods: {
    load(showLoading = true) {
      let loader;
      if (showLoading) {
        loader = this.$loading.show();
      }

      StoreService.getStoreData().then(res => {
        this.store = {
          name: res.data.name,
          cnpj: res.data.cnpj,
          email: res.data.email,
          image: res.data.image,
          cellphoneNumber: res.data.cellphoneNumber,
          addressStreet: res.data.address.street,
          addressNumber: res.data.address.number,
          addressComplement: res.data.address.complement,
          addressDistrict: res.data.address.district,
          addressCity: res.data.address.city,
          addressState: res.data.address.state,
          addressZipcode: res.data.address.zipcode,
        }

        loader.hide();
      }).catch(() => {
        loader.hide();
      })
    },
    save() {
      let loader = this.$loading.show();

      const data = {
        name: this.store.name,
        cnpj: this.store.cnpj,
        email: this.store.email,
        image: this.store.image,
        cellphoneNumber: this.store.cellphoneNumber,
        address: {
          street: this.store.addressStreet,
          number: this.store.addressNumber,
          complement: this.store.addressComplement,
          district: this.store.addressDistrict,
          zipcode: this.store.addressZipcode,
          city: this.store.addressCity,
          state: this.store.addressState,
        }
      }

      StoreService.updateStore(data).then(() => {
        this.$toast.open('Dados da loja atualizados com sucesso');
        this.load(false);

        loader.hide();
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao atualizar os dados');

        loader.hide();
      })
    },
    handleFilesUpload() {
      const files = this.$refs.file.files;
      const oldImage = this.store.image;

      for( var i = 0; i < files.length; i++ ) {
        this.store.image = 'temp';

        let file = files[i];
        
        let formData = new FormData();
        formData.append('file', file);

        StoreService.uploadImage(formData).then(res => {
          this.store.image = res.data.fileUrl;

          this.$forceUpdate();
        }).catch(() => {
          this.$toast.error('Ocorreu um erro ao enviar a imagem');
          this.store.image = oldImage;
        })
      }      
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <!-- <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Dados da Loja</h4>
            <p class="card-title-desc">Preencha as informações principais da loja e a localização.</p>

            <form>
              <div class="row mt-4 mb-4">
                <div class="col-sm-2 text-center">
                  <img
                    v-if="store.image !== 'temp' ? true : false"
                    class="rounded-circle avatar-xl"
                    alt="200x200"
                    :src="`${store.image || defaultStoreImage}`"
                    data-holder-rendered="true"
                  />

                   <div class="new-image" v-else>
                    <!-- <i class="bx bx-image-add"></i>
                    <p>Clique para adicionar uma nova imagem</p> -->
                    <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
                  </div>

                  <b-btn variant="link" @click="$refs.file.click()">Alterar Imagem</b-btn>
                  <input type="file" accept="image/*" name="file" ref="file" v-on:change="handleFilesUpload" />
                </div>

                <div class="col-sm-10">
                  <div class="row">
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="name">Nome da Loja</label>
                        <input id="name" name="name" type="text" class="form-control" v-model="store.name" />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="manufacturername">CNPJ</label>
                        <input id="cnpj" name="cnpj" type="text" class="form-control" v-model="store.cnpj" v-mask="'##.###.###/####-##'" />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="manufacturerbrand">E-mail</label>
                        <input id="email" name="email" type="email" class="form-control" v-model="store.email" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="name">Endereço (logradouro)</label>
                        <input id="name" name="name" type="text" class="form-control" v-model="store.addressStreet" />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label for="manufacturername">Número</label>
                        <input
                          id="cnpj"
                          name="cnpj"
                          type="text"
                          class="form-control"
                          v-model="store.addressNumber"
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="manufacturerbrand">Complemento</label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          class="form-control"
                          v-model="store.addressComplement"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="district">Bairro</label>
                        <input id="district" name="district" type="text" class="form-control" v-model="store.addressDistrict" />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="city">Cidade</label>
                        <input
                          id="city"
                          name="city"
                          type="text"
                          class="form-control"
                          v-model="store.addressCity"
                        />
                      </div>
                    </div>
                    <div class="col-sm-1">
                      <div class="form-group">
                        <label for="state">UF</label>
                        <input
                          id="state"
                          name="state"
                          type="state"
                          class="form-control"
                          v-model="store.addressState"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="manufacturerbrand">CEP</label>
                        <input
                          id="zipcode"
                          name="zipcode"
                          type="zipcode"
                          class="form-control"
                          v-model="store.addressZipcode"
                          v-mask="'#####-###'"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="cellphoneNumber">Telefone p/ Notificações</label>
                        <input id="cellphoneNumber" name="cellphoneNumber" type="tel" class="form-control" v-mask="'(##) #####-####'" v-model="store.cellphoneNumber" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-sm-right">
                <button type="button" class="btn btn-success btn-rounded" @click="save">Salvar Dados da Loja</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss">
  input[type="file"] {
    display: none;
  }
</style>